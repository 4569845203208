<template lang="pug">
  component(:is="is" :to="to" :href="href" @click="$emit('click')").sub-item
    span.sub-item__text() {{ text }}
    slot(name="aside")
</template>

<script>
export default {
  props: {
    text: {
      required: true,
      type: String
    },
    to: {
      type: Object
    },
    href: String
  },

  computed: {
    is() {
      if (this.to) return 'router-link'
      if (this.href) return 'a'
      return 'div'
    }
  }
}
</script>
